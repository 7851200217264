/* Reset and base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Onest", sans-serif;
}

body,
html {
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
  background-color: #fb970112;
  background-image: url("../public/images/background.png");
  background-size: cover;
  background-position: right;
}

/* Main container */
.app-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
  width: 100vw;
  padding: clamp(10px, 2vw, 20px);
  /* overflow: hidden; */
  /* background: linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%); */
  /* background-color: #fb970112; */
}

.logo {
  position: absolute;
  left: 20px;
  top: 20px;
}

/* Title styles */
.title {
  color: #111111;
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  letter-spacing: -0.5px;
  margin-top: 15px;
}

/* Wheel container */
.wheel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  /* background-color: white; */
  /* padding: clamp(1rem, 4vw, 2rem); */
  /* border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); */
  width: min(90%, 800px);
  height: auto;
  margin-top: 50px;
  transition: transform 0.3s ease;
}

/* Wheel wrapper */
.wheel-wrapper {
  position: relative;
  width: 550px;
  height: 550px;
  margin: 0 auto;
  /* aspect-ratio: 1; */
  visibility: hidden;

  opacity: 0;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

.center-img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 80px;
  width: auto;
  transform: translate(-50%, -50%);
}

.wheel-wrapper.loaded {
  visibility: visible;
  opacity: 1;
}

.wheel-wrapper > div:first-child {
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
  /* box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); */
}

/* Pointer styles */
.wheel-pointer {
  position: absolute;
  top: -5%;
  left: 50%;
  transform: translateX(-50%);
  width: min(10%, 40px);
  height: min(10%, 40px);
  background-color: #fb9701;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  z-index: 1000;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  transition: transform 0.3s ease;
}

.wheel-pointer::after {
  content: "";
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: min(5%, 20px);
  height: min(5%, 20px);
  background-color: #fb9701;
  border-radius: 50%;
  z-index: -1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Button styles */
.spin-button {
  background: linear-gradient(145deg, #3da5e0, #2980b9);
  background: #fb9701;
  color: white;
  padding: clamp(10px, 3vw, 15px) clamp(20px, 6vw, 30px);
  font-size: clamp(1rem, 2vw, 1.2rem);
  border: none;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  gap:10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: clamp(1rem, 2vh, 2rem) 0;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: min(80%, 300px);
}

.spin-button:disabled {
  background: linear-gradient(145deg, #cccccc, #bbbbbb);
  cursor: not-allowed;
  box-shadow: none;
}

.spin-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(61, 165, 224, 0.4);
}

.spin-button:active:not(:disabled) {
  transform: translateY(0);
  box-shadow: 0 2px 10px rgba(61, 165, 224, 0.3);
}

/* Result container */
.result-container {
  text-align: center;
  margin-top: clamp(1rem, 2vh, 2rem);
  padding: clamp(1rem, 3vw, 1.5rem);
  background-color: #f8f9fa;
  border-radius: 15px;
  width: 100%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.result-text {
  color: #2c3e50;
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  font-weight: 500;
  animation: fadeIn 0.5s ease-in;
}

.voucher-text {
  color: #28a745;
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  font-weight: 700;
  margin-top: 0.5rem;
  animation: fadeIn 0.5s ease-in;
}

.fast_animation {
  transition: all 0.2s ease-in-out;
}

.modal_container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-items: center;
  align-items: center;
}

.show_container {
  opacity: 100;
  visibility: visible;
}

.hide_container {
  opacity: 0;
  visibility: hidden;
}

.image_background {
  background-color: #fb970114;
}

.modal {
  width: 35vw;
  position: relative;
  right: 0;
  min-height: 30vh;
  height: auto;
  max-height: 95vh;
  margin: 0 auto;
  z-index: 50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 30px;
  background-color: white;
  transition: all 0.3s ease;
}

.modal.show {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}

.modal.hide {
  margin-top: 5rem;
  opacity: 0;
  visibility: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 20;
}

.banner-container {
  width: 90%;
  height: 100px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 3rem auto;
}

.banner {
  width: 100%;
  height: 100%;
  background-color: white;
  position: relative;
  border: 2px dashed #fb9701;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
}

.banner p {
  margin: 0;
  text-align: center;
  font-size: 18px;
  color: #000;
}

.corner {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #fb9701;
}

.top-left {
  top: -4px;
  left: -4px;
}

.top-right {
  top: -4px;
  right: -4px;
}

.bottom-left {
  bottom: -4px;
  left: -4px;
}

.bottom-right {
  bottom: -4px;
  right: -4px;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pointerBounce {
  0%,
  100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(5px);
  }
}

.wheel-wrapper.spinning .wheel-pointer {
  animation: pointerBounce 0.5s ease-in-out infinite;
}

/* Media Queries */
@media (max-width: 768px) {
  .wheel-container {
    width: 95%;
    padding: 1rem;
  }

  .wheel-wrapper {
    width: 90vw;
    height: 90vw;
  }

  .spin-button {
    width: 90%;
  }

  .modal {
    width: 100%;
  }
}

@media (max-height: 600px) {
  .app-container {
    padding: 10px;
  }

  .wheel-container {
    flex-direction: row;
    aspect-ratio: auto;
    height: 90vh;
    padding: 1rem;
    gap: 1rem;
  }

  .wheel-wrapper {
    width: auto;
    height: 80vh;
    margin-right: 1rem;
  }

  .title {
    font-size: clamp(1rem, 4vh, 1.5rem);
    margin-bottom: 0.5rem;
  }

  .spin-button {
    font-size: clamp(0.8rem, 2vh, 1rem);
    padding: 1vh 2vh;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  body {
  }

  .app-container {
    /* background: linear-gradient(135deg, #2c3e50 0%, #1a1a1a 100%); */
  }

  .title {
    color: #111111;
  }

  .wheel-container {
    /* background-color: #2c3e50; */
  }

  .result-container {
    background-color: #34495e;
  }

  .result-text {
    color: #ffffff;
  }

  .voucher-text {
    color: #2ecc71;
  }
}
